<template>
  <div>
    <b-alert
      variant="danger"
      :show="uniformData === undefined"
    >
      <h4 class="alert-heading">
        Errore dati Richiesta Divisa</h4>
      <div class="alert-body">
        Nessuna Richiesta di Divisa trovata con questo ID. Controlla la
        <b-link
          class="alert-link"
          :to="{ name: 'apps-uniformsreception-list' }"
        > Lista delle Richieste </b-link>
        per altre.
      </div>
    </b-alert>

    <template v-if="uniformData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <uniforms-view-uniform-info-card :uniform-data="uniformData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BAlert,
  BLink,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import UniformsViewUniformInfoCard from './UniformsViewUniformInfoCard.vue'
import uniformsStoreModule from '../uniformsStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    UniformsViewUniformInfoCard,
  },
  setup() {
    const uniformData = ref(null)

    const UNIFORMS_APP_STORE_MODULE_NAME = 'app-uniforms'

    // Register module
    if (!store.hasModule(UNIFORMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(UNIFORMS_APP_STORE_MODULE_NAME, uniformsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(UNIFORMS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(UNIFORMS_APP_STORE_MODULE_NAME)
      }
    })

    store
      .dispatch('app-uniforms/fetchUniform', { id: router.currentRoute.params.id })
      .then(response => {
        uniformData.value = response.data
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })

    return {
      uniformData,
    }
  },
}
</script>

<style></style>
