<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-row>
      <b-col
        class="mb-4 d-flex justify-content-center align-items-center"
        cols="12"
      >
        <feather-icon
          icon="ShoppingBagIcon"
          size="25"
          class="mr-2"
        />
        <h1>Informazioni Richiesta Divisa</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          ID Richiesta:
        </p>
        <h3 class="mb-1">
          #{{ uniformData.id }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Utente:
        </p>
        <div class="text-nowrap">
          <div class="d-flex align-items-center">
            <b-avatar
              size="25"
              :src="uniformData.user.avatar_file ? mediaUrl + uniformData.user.avatar_file : ''"
              :text="avatarText(uniformData.user.display_name)"
              :variant="`light-${resolveUserRoleVariant(uniformData.user.role_code)}`"
              class="mr-1"
            />
            <b-link>
              #{{ uniformData.user.anagrafico_id }} - {{ uniformData.user.display_name }}
            </b-link>
          </div>
        </div>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Reparto:
        </p>
        <div class="text-nowrap">
          <div class="d-flex align-items-center">
            <b-link>
              #{{ uniformData.department.id }} - {{ uniformData.department.name }}
            </b-link>
          </div>
        </div>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Creato il:
        </p>
        <h4 class="mb-1">
          {{ parsedate(uniformData.created_at) }}
        </h4>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="4"
      >
        <p class="mb-1">
          Approvato il:
        </p>
        <h4 class="mb-1">
          {{ parsedate(uniformData.approved_at) }}
        </h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="uniformData.items && uniformData.items.length > 0"
        class="mb-2"
        cols="12"
      >
        <p class="mb-1">
          Capi Richiesti:
        </p>
        <b-row
          v-for="item in uniformData.items"
          :key="item.id"
          class="mb-2"
          align-v="center"
        >
          <b-col
            class="d-flex align-items-center"
            style="font-size: 1.25rem;"
          >
            <span
              class="mr-2"
              style="font-size: 1.5rem;"
            >•</span>
            <b-badge
              variant="light-info"
              class="mr-2"
            >
              {{ item.uniform.name }}
            </b-badge>
            <b-badge
              variant="light-success"
            >
              x{{ item.quantity }}
            </b-badge>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="text-right mt-2"
        cols="12"
      >
        <b-button
          variant="outline-success"
          class="ml-1"
          @click="
            $bvModal.show('deliver-modal')
            selectedUniform = uniformData
          "
        >
          Conferma Consegna
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="deliver-modal"
      title="Consegna Divisa"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Consegna Divisa
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <p class="m-2">
        Sei sicuro di voler confermare la consegna di tutti i capi di questa richiesta?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            deliverUniform(selectedUniform.id)
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BLink, BModal, BAvatar, BBadge,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BLink,
    BModal,
    BAvatar,
    BBadge,
  },
  props: {
    uniformData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatarText,
      selectedUniform: null,
    }
  },
  computed: {
    mediaUrl() {
      return process.env.VUE_APP_MEDIA_URL
    },
  },
  methods: {
    parsedate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    resolveUserRoleVariant(role) {
      if (role === 'user') return 'info'
      if (role === 'hr') return 'warning'
      if (role === 'reception_hub') return 'warning'
      if (role === 'reception_colle') return 'warning'
      if (role === 'accountant') return 'warning'
      if (role === 'admin') return 'danger'
      return 'danger'
    },
    goBack() {
      this.$router.go(-1)
    },
    deliverUniform(id) {
      store
        .dispatch('app-uniforms/deliverUniforms', { id })
        .then(() => {
          this.selectedUniform = null
          this.$router.replace({ name: 'apps-uniformsreception-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Consegna Divisa #${id} conseguita con successo`,
                icon: 'ShoppingBagIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
</style>
